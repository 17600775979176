<template>
  <section id="home">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <!-- Filter Product -->
        <b-card
          border-variant="primary"
          class="p-0 m-0"
          style="min-height: 150px;"
        >
          <b-card-body style="padding: 0px 10px 0px 10px !important;">
            <home-product
              :product-type-filter.sync="productTypeFilter"
              :product-category-filter.sync="productCategoryFilter"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <!-- Filter Date -->
        <b-card
          border-variant="primary"
          class="p-0 m-0"
          style="min-height: 150px;"
          no-body
        >
          <b-overlay
            :show="dateLoading"
            rounded="sm"
          >
            <b-card-body style="padding: 0px 10px 0px 10px !important;">
              <home-date
                :date-tab.sync="dateTab"
                :year-filter.sync="yearFilter"
                :year-options="yearOptions"
                :month-filter.sync="monthFilter"
                :date-filter.sync="dateFilter"
              />
            </b-card-body>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row style="margin-top: 1rem;">
      <b-col
        cols="12"
        md="6"
      >
        <!-- Overview Total (Booking: Wait Approve, Approved, )-->
        <b-row>
          <b-col cols="12">
            <home-total-booking
              :data="homeTotalBookingData"
              :show-overlay="homeTotalBookingLoading"
              :product-type-filter="productTypeFilter"
              :product-category-filter="productCategoryFilter"
              :date-tab="dateTab"
              :year-filter="yearFilter"
              :month-filter="monthFilter"
              :date-filter="dateFilter"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <!-- Overview Total (Renting: Wait Approve, Approved, )-->
        <b-row>
          <b-col cols="12">
            <home-total-renting
              :data="homeTotalRentingData"
              :show-overlay="homeTotalRentingLoading"
              :product-type-filter="productTypeFilter"
              :product-category-filter="productCategoryFilter"
              :date-tab="dateTab"
              :year-filter="yearFilter"
              :month-filter="monthFilter"
              :date-filter="dateFilter"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'

import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import storeHome from './storeHome'
import constHome from './constHome'

import HomeProduct from './HomeProduct.vue'
import HomeDate from './HomeDate.vue'
import HomeTotalBooking from './HomeTotalBooking.vue'
import HomeTotalRenting from './HomeTotalRenting.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    BRow,
    BCol,

    HomeProduct,
    HomeDate,
    HomeTotalBooking,
    HomeTotalRenting,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      dateLoading: false,
      yearOptions: [],
      homeData: {},
    }
  },
  mounted() {
    this.getDefaultHome()
  },
  methods: {
    getDefaultHome() {
      this.dateLoading = true
      store
        .dispatch('store-home/getDefaultHome', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.homeData = response.data
          this.yearOptions = response.data.year_options
          this.dateLoading = false
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.dateLoading = false
        })
    },
    yearSelected(year) {
      this.yearFilter = year
    },
  },
  setup() {
    if (!store.hasModule('store-home')) store.registerModule('store-home', storeHome)
    onUnmounted(() => {
      if (store.hasModule('store-home')) store.unregisterModule('store-home')
    })

    const {
      getHomeTotal,
      // getHomePercent,
      // getHomeDefect,
      // getHomeRoom,
      // getHomeMonthly,
      refetchData,

      locale,
      productTypeFilter,
      productCategoryFilter,
      dateTab,
      yearFilter,
      monthFilter,
      dateFilter,

      homeTotalBookingLoading,
      homeTotalBookingData,
      homeTotalRentingLoading,
      homeTotalRentingData,
      // homeDefectLoading,
      // homeDefectData,
      // homeRoomLoading,
      // homeRoomData,
      // homePercentLoading,
      // homePercentData,
      // homeMonthlyLoading,
      // homeMonthlyData,
    } = constHome()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-home`))
    if (filterList !== null) {
      if (filterList.productType !== null) {
        productTypeFilter.value = filterList.productType
      } else {
        productTypeFilter.value = '0'
      }

      if (filterList.productCategory !== null) {
        if (productTypeFilter.value !== null && productTypeFilter.value !== '0') {
          productCategoryFilter.value = filterList.productCategory
        } else {
          productCategoryFilter.value = '0'
        }
      } else {
        productCategoryFilter.value = '0'
      }

      if (filterList.dateTab !== null) {
        dateTab.value = filterList.dateTab
      } else {
        dateTab.value = 0
      }

      if (filterList.year !== null) {
        yearFilter.value = filterList.year
      } else {
        yearFilter.value = null
      }

      if (filterList.month !== null) {
        monthFilter.value = filterList.month
      } else {
        monthFilter.value = null
      }

      if (filterList.date !== null) {
        dateFilter.value = filterList.date
      } else {
        dateFilter.value = null
      }
    } else {
      productTypeFilter.value = '0'
      productCategoryFilter.value = '0'
      dateTab.value = 0
      yearFilter.value = null
      monthFilter.value = null
      dateFilter.value = null
    }

    return {
      getHomeTotal,
      // getHomePercent,
      // getHomeDefect,
      // getHomeRoom,
      // getHomeMonthly,
      refetchData,

      locale,
      productTypeFilter,
      productCategoryFilter,
      dateTab,
      yearFilter,
      monthFilter,
      dateFilter,

      homeTotalBookingLoading,
      homeTotalBookingData,
      homeTotalRentingLoading,
      homeTotalRentingData,
      // homeDefectLoading,
      // homeDefectData,
      // homeRoomLoading,
      // homeRoomData,
      // homePercentLoading,
      // homePercentData,
      // homeMonthlyLoading,
      // homeMonthlyData,
    }
  },
}
</script>
