import { ref, watch } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function constHome() {
  // Use toast
  const toast = useToast()

  const locale = ref('th')
  const productTypeFilter = ref(null)
  const productCategoryFilter = ref(null)
  const dateTab = ref(0)
  const yearFilter = ref(null)
  const monthFilter = ref(null)
  const dateFilter = ref(null)
  const list = ref(0)

  const homeTotalBookingLoading = ref(false)
  const homeTotalBookingData = ref({})

  const homeTotalRentingLoading = ref(false)
  const homeTotalRentingData = ref({})

  const getHomeTotalBooking = (ctx, callback) => {
    homeTotalBookingLoading.value = true
    const params = {
      language: locale.value,
      type: productTypeFilter.value,
      category: productCategoryFilter.value,
      tab: dateTab.value,
      year: yearFilter.value,
      month: monthFilter.value,
      date: dateFilter.value,
    }
    store
      .dispatch('store-home/getHomeTotalBooking', params)
      .then(response => {
        homeTotalBookingLoading.value = false
        callback(response.data)
      })
      .catch(() => {
        homeTotalBookingLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: t2('Error fetching {data}', { data: t('Home') }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getHomeTotalRenting = (ctx, callback) => {
    homeTotalRentingLoading.value = true
    const params = {
      language: locale.value,
      type: productTypeFilter.value,
      category: productCategoryFilter.value,
      tab: dateTab.value,
      year: yearFilter.value,
      month: monthFilter.value,
      date: dateFilter.value,
    }
    store
      .dispatch('store-home/getHomeTotalRenting', params)
      .then(response => {
        homeTotalRentingLoading.value = false
        callback(response.data)
      })
      .catch(() => {
        homeTotalRentingLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: t2('Error fetching {data}', { data: t('Home') }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const refetchData = () => {
    getHomeTotalBooking(this, data => {
      if (data !== null) {
        homeTotalBookingData.value = data
      }
    })
    getHomeTotalRenting(this, data => {
      if (data !== null) {
        homeTotalRentingData.value = data
      }
    })
  }

  watch([locale, productTypeFilter, productCategoryFilter, dateTab, yearFilter, monthFilter, dateFilter], () => {
    localStorage.setItem(`${$themeConfig.app.session}-filter-home`, JSON.stringify({
      language: locale.value,
      productType: productTypeFilter.value,
      productCategory: productCategoryFilter.value,
      dateTab: dateTab.value,
      year: yearFilter.value,
      month: monthFilter.value,
      date: dateFilter.value,
    }))
    refetchData()
  })

  return {
    getHomeTotalBooking,
    getHomeTotalRenting,
    refetchData,

    locale,
    productTypeFilter,
    productCategoryFilter,
    dateTab,
    yearFilter,
    monthFilter,
    dateFilter,
    list,

    homeTotalBookingLoading,
    homeTotalBookingData,
    homeTotalRentingLoading,
    homeTotalRentingData,
  }
}
