<template>
  <section id="home-date">
    <b-row>
      <!-- Date -->
      <b-col
        cols="12"
        md="12"
        class="m-0"
      >
        <b-tabs
          v-model="dateTabLocal"
          pills
          card
          style="margin: 0px; padding: 4px 0px 4px 0px;"
        >
          <b-tab
            :title="$t('All Select')"
            style="margin:  ; padding: 4px 0px 4px 0px;"
          >
            <b-card-text
              class="pl-2 pt-0"
              style="margin: -1rem 0rem 0rem 0rem;"
            >
              <b-badge
                style="font-size: 16px; padding: 10px; font-weight: 500;"
                variant="light-secondary"
              >
                {{ $t('FormHome.All Select Everyday') }}
              </b-badge>
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('FormHome.Choose Year')"
            style="margin: 0px; padding: 0px;"
          >
            <b-row
              class="pl-2"
              style="margin: -1rem 0rem 0rem 0rem;"
            >
              <b-col cols="8">
                <v-select
                  v-model="yearFilterLocal"
                  :options="yearOptions"
                  style="width: auto; margin-top: 0px;"
                  :clearable="true"
                  :reduce="val => val.value"
                  placeholder="ปี"
                  class="style-year"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="$t('FormHome.Choose Month')"
            style="margin: 0px; padding: 0px;"
          >
            <b-row
              class="pl-2"
              style="margin: -1rem 0rem 0rem 0rem;"
            >
              <b-col
                cols="8"
              >
                <vue-monthly-picker
                  v-model="monthFilterLocal"
                  :month-labels="monthLabels"
                  date-format="MMMM Y"
                  place-holder="เดือน ปี"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="`${$t('FormHome.Choose Day')} (${$t('FormHome.During')})`"
            style="margin: 0px; padding: 0px;"
          >
            <b-row
              class="pl-2"
              style="margin: -1rem 0rem 0rem 0rem;"
            >
              <b-col cols="8">
                <flat-pickr
                  v-model="dateFilterLocal"
                  :config="{
                    mode: 'range',
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    maxDate: 'today',
                    altInput: true,
                    altFormat: 'd F Y',
                  }"
                  class="form-control"
                  :placeholder="$t('Date')"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VueMonthlyPicker from 'vue-monthly-picker'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    vSelect,
    flatPickr,
    VueMonthlyPicker,
  },
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
    dateTab: {
      type: Number,
      default: 0,
    },
    yearFilter: {
      type: String,
      default: '',
    },
    yearOptions: {
      type: Array,
      default: () => [],
    },
    monthFilter: {
      type: String,
      default: '',
    },
    dateFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
  },
  computed: {
    dateTabLocal: {
      get() {
        return this.dateTab
      },
      set(value) {
        this.$emit('update:dateTab', value)
      },
    },
    yearFilterLocal: {
      get() {
        return this.yearFilter
      },
      set(value) {
        let year
        if (value !== null) {
          year = value
        } else {
          year = ''
        }
        this.$emit('update:yearFilter', year)
      },
    },
    monthFilterLocal: {
      get() {
        return this.monthFilter
      },
      set(value) {
        let month
        if (value !== null) {
          month = this.$moment(value).format('YYYY-MM')
        } else {
          month = ''
        }
        this.$emit('update:monthFilter', month)
      },
    },
    dateFilterLocal: {
      get() {
        return this.dateFilter
      },
      set(value) {
        let date
        if (value !== null) {
          date = value
        } else {
          date = ''
        }
        this.$emit('update:dateFilter', date)
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
.vue-monthly-picker {
  .placeholder {
    color: #b3b3b3 !important;
  }
  .input {
    height: 2.8rem !important;
  }
}
</style>

<style>
.style-year .vs__search::placeholder,
.style-year .vs__dropdown-menu {
  color: #b3b3b3;
  text-transform: lowercase;
}
</style>
