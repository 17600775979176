<template>
  <b-card
    v-if="data"
    no-body
    class="p-0 m-0 card-list-home-total-renting"
    border-variant="secondary"
    style="min-height: 400px;"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <b-card-header>
        <h4
          class="mb-0"
          style="font-size: 18px; font-weight: 400;"
        >
          {{ `${$t('FormHome.Total Renting')} (${$t('Entries')})` }}
        </h4>
        <b-card-text class="font-medium-5 mb-0">
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
            class="text-muted cursor-pointer"
          />
        </b-card-text>
      </b-card-header>

      <b-card-body>
        <!-- total -->
        <b-row class="mx-25 mt-0 mb-2">
          <b-col
            v-for="(item, index) in data.total_renting"
            :key="index"
            cols="12"
            md="4"
            :class="item.customClass"
            class="total-item cursor-pointer"
            @click="clickOverviewTotal(item)"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-1"
              >
                <b-avatar
                  size="60"
                  :variant="item.avatarVariant"
                >
                  <feather-icon
                    size="30"
                    :icon="item.avatar"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4
                  class="font-weight-bolder mb-0"
                  :class="item.text"
                >
                  {{ item.qty }}
                  <span>{{ item.unit }}</span>
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.title }}
                </b-card-text>
                <b-card-text class="font-small-1 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row
          style="background-color: #eeeeee; width: 100%; height: 2px;"
          class="mt-2"
        >
          <b-col cols="12" />
        </b-row>
        <h4
          class="mt-1 mb-0"
          style="font-size: 18px; font-weight: 400;"
        >
          {{ $t('Renting') }}
        </h4>
        <b-row v-if="data.data_total">
          <b-col cols="12">
            <div class="d-flex">
              <b-card
                no-body
                class="p-0 m-0 mr-1"
                style="margin-top: 0.8rem !important; width: 190px;"
              >
                <div class="mx-50 my-50">
                  <h6>{{ $t('StatusMessage.wait_approve') }}</h6>
                  <h5 class="font-weight-bolder">
                    {{ `${data.data_total.wait_approve.total} ${$t('PageHome.Document')}` }}
                  </h5>
                </div>
                <div class="p-0 m-0">
                  <vue-apex-charts
                    type="radialBar"
                    width="100%"
                    :options="chartWaitApprove.options"
                    :series="data.data_total.wait_approve.percent"
                    class="p-0 m-0"
                  />
                </div>
              </b-card>

              <b-card
                no-body
                class="p-0 m-0 mr-1"
                style="margin-top: 0.8rem !important; width: 190px;"
              >
                <div class="mx-50 my-50">
                  <h6>{{ $t('StatusMessage.approved') }}</h6>
                  <h5 class="font-weight-bolder">
                    {{ `${data.data_total.approved.total} ${$t('PageHome.Document')}` }}
                  </h5>
                </div>
                <div class="p-0 m-0">
                  <vue-apex-charts
                    type="radialBar"
                    width="100%"
                    :options="chartApproved.options"
                    :series="data.data_total.approved.percent"
                    class="p-0 m-0"
                  />
                </div>
              </b-card>

              <b-card
                no-body
                class="p-0 m-0"
                style="margin-top: 0.8rem !important; width: 190px;"
              >
                <div class="mx-50 my-50">
                  <h6>{{ $t('StatusMessage.complete') }}</h6>
                  <h5 class="font-weight-bolder">
                    {{ `${data.data_total.complete.total} ${$t('PageHome.Document')}` }}
                  </h5>
                </div>
                <div class="p-0 m-0">
                  <vue-apex-charts
                    type="radialBar"
                    width="100%"
                    :options="chartComplete.options"
                    :series="data.data_total.complete.percent"
                    class="p-0 m-0"
                  />
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>

    <!-- <home-total-renting-list
      v-model="showModalOverviewTotalList"
      :title="titleOverviewTotalList"
      :filter="filterList"
      :data-type="dataType"
      @update-home-total-renting-list="updateOverviewTotalList"
      @discard-home-total-renting-list="discardOverviewTotalList"
    />

    <home-satisfaction-list
      v-model="showModalOverviewSatisfactionList"
      :title="titleOverviewSatisfactionList"
      :filter="filterList"
      :data-type="dataType"
      @update-home-satisfaction-list="updateOverviewSatisfactionList"
      @discard-home-satisfaction-list="discardOverviewSatisfactionList"
    /> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardText,
  BOverlay,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
// import OverviewTotalList from './total_list/OverviewTotalList.vue'
// import OverviewSatisfactionList from './satisfaction_list/OverviewSatisfactionList.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BOverlay,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    // OverviewTotalList,
    // OverviewSatisfactionList,
    FeatherIcon,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
    productTypeFilter: {
      type: String,
      default: '',
    },
    productCategoryFilter: {
      type: String,
      default: '',
    },
    dateTab: {
      type: Number,
      default: 0,
    },
    yearFilter: {
      type: String,
      default: '',
    },
    monthFilter: {
      type: String,
      default: '',
    },
    dateFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModalOverviewTotalList: false,
      showModalOverviewSatisfactionList: false,
      filterList: {
        productTypeFilter: '',
        productCategoryFilter: '',
        dateTab: '',
        yearFilter: '',
        monthFilter: '',
        dateFilter: '',
      },
      dataType: 'total_complete_cases',
      titleOverviewTotalList: '',
      titleOverviewSatisfactionList: '',
      chartWaitApprove: {
        series: [],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -20,
              bottom: -15,
            },
          },
          colors: [$themeColors.warning],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
              track: {
                background: '#e9ecef',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
      chartApproved: {
        series: [],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -20,
              bottom: -15,
            },
          },
          colors: [$themeColors.info],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
              track: {
                background: '#e9ecef',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
      chartComplete: {
        series: [],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -20,
              bottom: -15,
            },
          },
          colors: [$themeColors.success],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
              track: {
                background: '#e9ecef',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  watch: {
    productTypeFilter() {
      this.filterList.productTypeFilter = this.productTypeFilter
    },
    productCategoryFilter() {
      this.filterList.productCategoryFilter = this.productCategoryFilter
    },
    dateTab() {
      this.filterList.dateTab = this.dateTab
    },
    yearFilter() {
      this.filterList.yearFilter = this.yearFilter
    },
    monthFilter() {
      this.filterList.monthFilter = this.monthFilter
    },
    dateFilter() {
      this.filterList.dateFilter = this.dateFilter
    },
  },
  mounted() {
    this.filterList = {
      productTypeFilter: this.productTypeFilter,
      productCategoryFilter: this.productCategoryFilter,
      dateTab: this.dateTab,
      yearFilter: this.yearFilter,
      monthFilter: this.monthFilter,
      dateFilter: this.dateFilter,
    }
  },
  methods: {
    clickOverviewTotal(total) {
      this.titleOverviewTotalList = `${this.$i18n.t('List')} - ${total.title} ${total.subtitle}`
      this.dataType = total.code
      this.showModalOverviewTotalList = true
    },
    clickOverviewSatisfaction(total) {
      this.titleOverviewSatisfactionList = `${this.$i18n.t('List')} - ${total.title} ${total.subtitle}`
      this.dataType = total.code
      this.showModalOverviewSatisfactionList = true
    },
    updateOverviewTotalList() {
    },
    discardOverviewTotalList() {
    },
    updateOverviewSatisfactionList() {
    },
    discardOverviewSatisfactionList() {
    },
  },
}
</script>

<style lang="scss">
.card-list-home-total-renting {
  .total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 2.8;
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    .avatar {
      margin-right: 1.2rem;
      .avatar-content {
        width: 62px;
        height: 62px;
      }
    }

    .total-title {
      font-size: 16px;
      margin-top: 1.4rem;
    }
  }
}
</style>
