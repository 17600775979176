<template>
  <section id="home-product">
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="m-0"
        >
          <label>{{ $t('FormProduct.ProductType') }}</label>
          <v-select
            :value="productTypeFilter"
            :options="productTypeOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            label="name"
            @input="(val) => $emit('update:productTypeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="m-0"
        >
          <label>{{ $t('FormProduct.ProductCategory') }}</label>
          <v-select
            :value="productCategoryFilter"
            :options="productCategoryOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            label="name"
            @input="(val) => $emit('update:productCategoryFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px;">
        <b-col>
          <b-badge
            style="font-size: 16px; padding: 10px; font-weight: 500; width: 100%"
            variant="light-primary"
          >
            {{ $t('Product Filter') }}
          </b-badge>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeProductType from '@/views/master/product_type/storeProductType'
import storeProductCategory from '@/views/master/product_category/storeProductCategory'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BOverlay,
    vSelect,
  },
  props: {
    productTypeFilter: {
      type: String,
      default: '',
    },
    productCategoryFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      productTypeOptions: [],
      productCategoryOptions: [],
    }
  },
  watch: {
    productTypeFilter(value) {
      if (value !== '' && value !== '0') {
        this.$emit('update:productCategoryFilter', '0')
        this.getProductCategoryOptions()
      } else {
        this.productCategoryOptions = [{
          name: this.$i18n.t('All'),
          value: '0',
        }]
        this.$emit('update:productCategoryFilter', '0')
      }
    },
  },
  mounted() {
    this.getProductTypeOptions()
    if (this.productTypeFilter !== '' && this.productTypeFilter !== '0') {
      this.$emit('update:productCategoryFilter', '0')
      this.getProductCategoryOptions()
    } else {
      this.productCategoryOptions = [{
        name: this.$i18n.t('All'),
        value: '0',
      }]
      this.$emit('update:productCategoryFilter', '0')
    }
  },
  methods: {
    getProductTypeOptions() {
      this.showOverlay = true
      store
        .dispatch('store-product-type/getProductTypeOptions',
          {
            language: this.$i18n.locale,
          })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.productTypeOptions = [{
            name: this.$i18n.t('All'),
            value: '0',
          }]
          this.productTypeOptions.push(...response.data)
        })
        .catch(error => {
          this.showOverlay = false
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    getProductCategoryOptions() {
      this.showOverlay = true
      store
        .dispatch('store-product-category/getProductCategoryOptions',
          {
            language: this.$i18n.locale,
            productType: this.productTypeFilter,
          })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.productCategoryOptions = [{
            name: this.$i18n.t('All'),
            value: '0',
          }]
          this.productCategoryOptions.push(...response.data)
        })
        .catch(error => {
          this.showOverlay = false
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
  },
  setup() {
    if (!store.hasModule('store-product-type')) store.registerModule('store-product-type', storeProductType)
    if (!store.hasModule('store-product-category')) store.registerModule('store-product-category', storeProductCategory)
    onUnmounted(() => {
      if (store.hasModule('store-product-type')) store.unregisterModule('store-product-type')
      if (store.hasModule('store-product-category')) store.unregisterModule('store-product-category')
    })

    return {}
  },

}
</script>

<style>

</style>
