import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultHome(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_home', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // for total booking
    getHomeTotalBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_home_total_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // for total renting
    getHomeTotalRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_home_total_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
